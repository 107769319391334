<template>
  <div v-if="jobPrepare">
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ job.name }}
      </h2>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card class="height-card">
          <b-row class="position-relative">
            <b-col cols="3">
              <p class="mb-50 title-style">OBRA</p>
              <b-link
                v-if="job.project"
                :to="{ name: 'viewProject', params: { id: job.project.id } }"
                class="link"
                >{{ job.project.name }}</b-link
              >
            </b-col>
            <b-col cols="3">
              <p class="mb-50 title-style">CATEGORÍA</p>
              <p class="font-weight-bolder">{{ job.category.name }}</p>
            </b-col>
            <b-col cols="3">
              <p class="mb-50 title-style">ESTADO</p>
              <div>
                <b-badge :variant="statusJobsVariants[job.status]">
                  {{ statusJobsText[job.status] }}
                </b-badge>
              </div>
            </b-col>
            <b-col cols="3">
              <p class="mb-50 title-style">FECHAS</p>
              <p class="font-weight-bolder">
                {{ TimestampToFlatPickr(job.date_ini) }} -
                {{ TimestampToFlatPickr(job.date_fin) }}
              </p>
            </b-col>
            <span
              :class="
                collapseInfo
                  ? 'text-danger collapse-button'
                  : 'text-danger collapse-button collapsed'
              "
              :aria-expanded="collapseInfo ? 'true' : 'false'"
              aria-controls="collapse-info"
              @click="collapseInfo = !collapseInfo"
            >
              <feather-icon
                :icon="collapseInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="22"
              />
            </span>
            <b-col cols="6">
              <p class="mb-50 title-style">DESCRIPCIÓN</p>
              <div v-html="job.description"></div>
            </b-col>
            <b-col cols="12">
              <b-collapse
                id="collapse-info"
                v-model="collapseInfo"
                class="mt-2"
              >
                <b-row>
                  <b-col cols="12">
                    <p class="mb-50 title-style">DOCUMENTOS</p>
                    <b-link
                      v-for="d in job.documents"
                      :key="d.id"
                      :href="d.path"
                      target="_blank"
                    >
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mr-1 mb-50"
                      >
                        {{ d.name }}
                      </b-button>
                    </b-link>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-link
                :to="{ name: 'editJob', params: { id: job.id } }"
                class="d-inline-block text-dark pt-2"
              >
                <span class="">Editar</span>
                <span class="pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="job.journeyTask">
        <b-card no-body>
          <div class="d-flex align-items-center justify-content-between p-2">
            <h5 class="m-0">TAREAS</h5>
            <div>
              <a
                :href="urlExcelTask(job.id)"
                target="_blank"
                download
                class="btn btn-primary btn-sm mr-1"
              >
              Descargar horas
              </a>
              <b-button
                v-if="job.status !== 'complete'"
                variant="primary"
                size="sm"
                class="mr-1 mb-50"
                @click="openCreateTask"
              >
                Añadir nueva tarea
              </b-button>
            </div>
          </div>
          <b-table
            v-if="job.journeyTask.data.length"
            hover
            responsive
            :items="job.journeyTask.data"
            :fields="columnsTasks"
          >
            <template #cell(day)="data">
              {{ TimestampToFlatPickr(data.item.day) }}
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusTasksVariants[data.item.status]">
                {{ statusTasksText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(user)="data">
              <div v-if="data.item.user">
                <b-avatar size="md" :src="data.item.user.avatar_url" />
                <b-link
                  :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
                  class="link"
                >
                  <span class="ml-1">{{ data.item.user.name }}</span>
                </b-link>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewTask', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-dark"
                  @click="openEditTask(data.item.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </span>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="
                    deleteTask(
                      data.item.id,
                      `'${data.item.user.name} - ${TimestampToFlatPickr(
                        data.item.day
                      )}'`
                    )
                  "
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="showCreateTask"
      :hide-footer="true"
      title="Crear tarea"
      size="lg"
    >
      <form-create-task :job="job" />
    </b-modal>
    <b-modal
      v-model="showEditTask"
      :hide-footer="true"
      title="Editar tarea"
      size="lg"
    >
      <form-edit-task :job="job" :task="task" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BCollapse,
  BLink,
  BButton,
  BTable,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { TimestampToFlatPickr } from "@/libs/helpers";
import { config } from "@/shared/app.config";
import FormCreateTask from "@/views/tasks/FormCreateTask";
import FormEditTask from "@/views/tasks/FormEditTask";

export default {
  components: {
    BButton,
    BBadge,
    BLink,
    BRow,
    BCol,
    BCard,
    BCollapse,
    BTable,
    FormCreateTask,
    FormEditTask,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusJobsVariants: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      statusTasksVariants: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      showCreateTask: false,
      showEditTask: false,
      collapseInfo: false,
      jobPrepare: false,
      columnsTasks: [
        {
          label: "Usuario",
          key: "user",
        },
        {
          label: "Día",
          key: "day",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Imputadas",
          key: "total_hours",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      job: "jobs/getJob",
      task: "tasks/getTask",
    }),
  },
  methods: {
    ...mapActions({
      getJob: "jobs/getJob",
      delete: "tasks/delete",
      getTask: "tasks/getTask",
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f);
    },
    openCreateTask() {
      this.showCreateTask = true;
    },
    async openEditTask(id) {
      await this.getTask(id);
      this.showEditTask = true;
    },
    urlExcelTask(id) {
      return `${
        config.webApiBase
      }/tasks/export/${id}`
    },
    deleteTask(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
  async created() {
    await this.getJob(this.$route.params.id);
    this.jobPrepare = true;
  },
};
</script>
